import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const _Conseillers = ({ data }) => (
  <div id="conseillers">
    <h3 className="conseillers-title">{data.titre}</h3>
    <p className="conseillers-subtitle" dangerouslySetInnerHTML={{ __html: data.description }} />
    <div className="items">
      {data.options.map((object, i) => (
        <Conseiller data={object} key={i}/>
      ))}
    </div>
  </div>
)

const Conseiller = ({ data }) => {
  const image = getImage(data.personne)
  return (
    <div id="conseiller">
      <div className="img-container">
        <GatsbyImage image={image} alt={data.personne.description}/>
        <p>{data.personne.description}</p>
      </div>
      <div className="text">
        <div to="/nous-rejoindre">
          <h5>{data.titre}</h5>
        </div>
        <p>{data.description}</p>
      </div>
    </div>
    )
}

const Conseillers = ({ data }) => (
  <StaticQuery
    query={graphql`
      {
        allContentfulConseillers {
          nodes {
            description
            titre
            options {
              titre
              description
              personne {
                description
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality:100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `}
    render={data => <_Conseillers data={data.allContentfulConseillers.nodes[0]} />}
  />
)

_Conseillers.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Conseillers;
